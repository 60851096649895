import { RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import React from "react";
import { styled } from "styled-components";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import Image from "next/image";
const Logo = styled(Image)`
  padding: 0px;
  object-fit: contain;
`;

const OutscalLogo = () => {
  return (
    <RowDiv data-analytics={[UIElements.LINK, "Outscal Logo"]}>
      <Logo
        quality={1}
        alt={"Outscal Logo"}
        width={28}
        height={28}
        src={"/assets/images/animatedlogo.gif"}
      />
      <Logo
        quality={1}
        alt={"outscal logo"}
        width={100}
        height={28}
        src={"/assets/images/fullTextLogo.png"}
      />
    </RowDiv>
  );
};

export default OutscalLogo;
